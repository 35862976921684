// TaskForm.js
import React, { useState, useRef } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import SendIcon from '@mui/icons-material/Send';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PhoneIcon from '@mui/icons-material/Phone';
import MessageIcon from '@mui/icons-material/Message';
import CircularProgress from '@mui/material/CircularProgress';
import { Link } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import swal from 'sweetalert';

const OrderConfirm = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [bulkNumbers, setBulkNumbers] = useState('');
  const messageInputRef = useRef(null);

  const characterLimit = 160;

  const handleSubmit = async () => {
    setIsLoading(true);
  
    try {
      const numbersArray = bulkNumbers.split('\n').map((number) => number.trim());
  
      const requests = numbersArray.map(async (number) => {
        try {
          const response = await fetch('http://localhost:3001/QueueTask', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ phone_number: number, message: message }),
          });
  
          const data = await response.json();
  
          if (!response.ok) {
            throw new Error(`Failed to send to ${number} : ${data.message}`);
          }
  
          return data;
        } catch (error) {
          console.error(`Error sending to ${number}:`, error);
          throw new Error(`Error sending to ${number}: ${error.message}`);
        }
      });
  
      const results = await Promise.all(requests);
  
      // Check if all requests were successful
      if (results.every((result) => result && result.status === 'success')) {
        swal('Success', 'Bulk messages sent successfully.', 'success');
        setBulkNumbers('');
        setMessage('');
      } else {
        const errorDetails = results.filter((result) => !result || result.status !== 'success').map((result, index) => `${result && result.message}`).join('\n');
        throw new Error(`${errorDetails}`);
        
      }
    } catch (error) {
      console.error('Error submitting task:', error);
      swal('Sorry', error.message || 'Failed to send task. Please try again.', 'error');
    } finally {
      setIsLoading(false);
    }
  };
  

  const isFormValid = () => {
    // Basic phone number validation (numeric characters only)
    const isValidPhoneNumber = /^\d+$/.test(phoneNumber);
  
    return (
      (!isValidPhoneNumber && phoneNumber.trim() !== '' && message.trim() !== '' && message.length <= characterLimit) ||
      (bulkNumbers.trim() !== '' && message.trim() !== '' && message.length <= characterLimit)
    );
  };
  

  const handlePhoneNumberChange = (e) => {
    const inputValue = e.target.value.replace(/[^\d+]/g, '');
    setPhoneNumber(inputValue);
  };

  return (
    <Grid container spacing={2} sx={{ mt: 3 }}>
      <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
        <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
          <IconButton color="primary" aria-label="Go back to Home">
            <ArrowBackIcon />
          </IconButton>
        </Link>
        <Typography variant="h5" sx={{ marginLeft: '10px' }}>
        Test Order Confirmation
        </Typography>
      </Grid>
    
      
        <Grid item xs={12}>
          <TextField
            fullWidth
            label={
              <>
                <PhoneIcon style={{ marginRight: '8px', verticalAlign: 'middle' }} />
                Import Mobile Numbers
              </>
            }
            multiline
            placeholder="919484714204
919489411023
919489411023"
            inputProps={{ inputMode: 'numeric' }}
            rows={4}
            value={bulkNumbers}
            onChange={(e) => setBulkNumbers(e.target.value)}
          />
        </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label={
            <>
              <MessageIcon style={{ marginRight: '8px', verticalAlign: 'middle' }} />
              Message {message.length}/{characterLimit} characters

            </>
          }
          multiline
          rows={4}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          inputRef={messageInputRef}
          placeholder="Type your message here"
        />
        {message.length > characterLimit && (
          <Typography variant="caption" color="error" sx={{ mt: 1 }}>
            Character limit exceeded!
          </Typography>
        )}
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          fullWidth
          disabled={!isFormValid() || isLoading}
          onClick={handleSubmit}
          endIcon={isLoading ? <CircularProgress size={20} /> : <SendIcon />}
          aria-busy={isLoading}
          aria-disabled={!isFormValid() || isLoading}
        >
          {isLoading ? 'Sending...' : 'Test Order Confirmation'}
        </Button>
      </Grid>
    </Grid>
  );
};

export default OrderConfirm;
