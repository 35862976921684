// App.js
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import TaskForm from './TaskForm';
import TaskList from './TaskList';
import TaskDetails from './TaskDetails';
import OrderConfirm from './OrderConfirm';
import Login from './Login';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import AddIcon from '@mui/icons-material/Add';
import SendIcon from '@mui/icons-material/SendRounded';
import GroupAddRoundedIcon from '@mui/icons-material/GroupAddRounded';
import InboxRoundedIcon from '@mui/icons-material/InboxRounded';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import CampaignRoundedIcon from '@mui/icons-material/CampaignRounded';
import SpeakerNotesRoundedIcon from '@mui/icons-material/SpeakerNotesRounded';
import AllInboxRoundedIcon from '@mui/icons-material/AllInboxRounded';

const Home = () => (
  <div>
    <Typography variant="h4" align="center" style={{ marginTop: '40px' }} gutterBottom>
      WM API Dashboard
    </Typography>
    <Grid container spacing={3} justifyContent="center" alignItems="center" style={{ marginTop: '20px' }}>
      {/* <Grid item xs={12} sm={6} md={4}>
        <Link to="/create-task" style={{ textDecoration: 'none', color: 'inherit' }}>
          <Card variant="outlined" style={{ borderRadius: '16px', height: '100%' }}>
            <CardContent>
              <SendIcon style={{ fontSize: '3rem', marginBottom: '10px' }} />
              <Typography variant="h6">Send Message</Typography>
            </CardContent>
          </Card>
        </Link>
      </Grid> */}

      <Grid item xs={12} sm={6} md={4}>
        <Link to="/tasks" style={{ textDecoration: 'none', color: 'inherit' }}>
          <Card variant="outlined" style={{ borderRadius: '16px', height: '100%' }}>
            <CardContent>
              <InboxRoundedIcon style={{ fontSize: '3rem', marginBottom: '10px' }} />
              <Typography variant="h6">View Tasks</Typography>
            </CardContent>
          </Card>
        </Link>
      </Grid>


      <Grid item xs={12} sm={6} md={4}>
        <Link to="/order-confirmation" style={{ textDecoration: 'none', color: 'inherit' }}>
          <Card variant="outlined" style={{ borderRadius: '16px', height: '100%' }}>
            <CardContent>
              <CampaignRoundedIcon style={{ fontSize: '3rem', marginBottom: '10px' }} />
              <Typography variant="h6">Order Confirmation</Typography>
            </CardContent>
          </Card>
        </Link>
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <Link to="/create-task" style={{ textDecoration: 'none', color: 'inherit' }}>
          <Card variant="outlined" style={{ borderRadius: '16px', height: '100%' }}>
            <CardContent>
              <SpeakerNotesRoundedIcon style={{ fontSize: '3rem', marginBottom: '10px' }} />
              <Typography variant="h6">Announcements</Typography>
            </CardContent>
          </Card>
        </Link>
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <Link to="/create-task" style={{ textDecoration: 'none', color: 'inherit' }}>
          <Card variant="outlined" style={{ borderRadius: '16px', height: '100%' }}>
            <CardContent>
              <GroupAddRoundedIcon style={{ fontSize: '3rem', marginBottom: '10px' }} />
              <Typography variant="h6">New Events</Typography>
            </CardContent>
          </Card>
        </Link>
      </Grid>

      {/* <Grid item xs={12} sm={6} md={4}>
        <Link to="/create-task" style={{ textDecoration: 'none', color: 'inherit' }}>
          <Card variant="outlined" style={{ borderRadius: '16px', height: '100%' }}>
            <CardContent>
              <AllInboxRoundedIcon style={{ fontSize: '3rem', marginBottom: '10px' }} />
              <Typography variant="h6">View Group Messages</Typography>
            </CardContent>
          </Card>
        </Link>
      </Grid> */}
    </Grid>
  </div>
);

const App = () => {
  const [token, setToken] = useState('');

  useEffect(() => {
    // Retrieve the token from localStorage on page load
    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      setToken(storedToken);
    }
  }, []);

  const handleLogin = (newToken) => {
    setToken(newToken);
    // Save the token to localStorage
    localStorage.setItem('token', newToken);
  };

  const handleLogout = () => {
    // Clear the token from localStorage and reset the state
    localStorage.removeItem('token');
    setToken('');
  };

  return (
    <CssBaseline>
      <Container>
        <Router>
          {token ? (
            <>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/create-task" element={<TaskForm />} />
                <Route path="/order-confirmation" element={<OrderConfirm />} />
                <Route path="/tasks" element={<TaskList />} />
                <Route path="/task-details/:taskId" element={<TaskDetails />} />
              </Routes>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleLogout}
                style={{ marginTop: '10px', borderRadius: '16px' }}
              >
                <ExitToAppIcon style={{ marginRight: '8px' }} />
                Logout
              </Button>
            </>
          ) : (
            <Routes>
              <Route path="/" element={<Login onLogin={handleLogin} />} />
            </Routes>
          )}
        </Router>
      </Container>
    </CssBaseline>
  );
};

export default App;
