// TaskList.js
import React, { useState, useEffect } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import CheckIcon from '@mui/icons-material/Check';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import PhoneIcon from '@mui/icons-material/Phone';
import MessageIcon from '@mui/icons-material/Message';
import ArrowBackIcon from '@mui/icons-material/ArrowBack'; // Import the ArrowBackIcon
import { Link } from 'react-router-dom';

const TaskList = () => {
  const [tasks, setTasks] = useState([]);
  const [loadingTaskId, setLoadingTaskId] = useState(null);

  useEffect(() => {
    const fetchTasks = async () => {
      try {
        const response = await fetch('http://localhost:3001/TaskList');
        const data = await response.json();

        if (data.status === 'success') {
          setTasks(data.feedbacks);
        } else {
          console.error('Error fetching tasks:', data.message);
        }
      } catch (error) {
        console.error('Error fetching tasks:', error);
      }
    };

    fetchTasks();
  }, []);

  const getStatusIcon = (status) => {
    if (status === 1) {
      return <CheckIcon color="primary" />;
    } else if (status === 0) {
      return <CircularProgress size={20} />;
    }

    return null;
  };

  const handleTaskClick = (taskId) => {
    setLoadingTaskId(taskId);
  };

  return (
    <Grid container spacing={3} alignItems="center">
      <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', marginTop:'50px' }}>
      <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
          <IconButton color="primary" aria-label="Go back to Home">
            <ArrowBackIcon />
          </IconButton>
        </Link>
        <Typography variant="h5" sx={{ marginLeft: '10px' }}>
          View Messages
        </Typography>
      </Grid>
      {tasks.map((task) => (
        <Grid item xs={12} sm={6} md={4} key={task.id}>
          <Link
            to={`/task-details/${task.id}`}
            style={{ textDecoration: 'none', color: 'inherit' }}
            onClick={() => handleTaskClick(task.id)}
          >
            <Card
              key={task.id}
              variant="outlined"
              style={{
                borderRadius: '12px',
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                cursor: 'pointer',
                textDecoration: 'none',
                height: '100%',
              }}
            >
              <CardContent>
                <ListItem>
                  <ListItemText
                    primary={
                      <>
                        <PhoneIcon style={{ marginRight: '8px', verticalAlign: 'middle' }} />
                        {task.phone_number}
                      </>
                    }
                    secondary={
                      <>
                        <MessageIcon style={{ marginRight: '8px', verticalAlign: 'middle' }} />
                        {task.message}
                      </>
                    }
                  />
                  {loadingTaskId === task.id ? (
                    <CircularProgress size={20} />
                  ) : (
                    getStatusIcon(task.sent)
                  )}
                </ListItem>
                <Divider />
              </CardContent>
            </Card>
          </Link>
        </Grid>
      ))}
    </Grid>
  );
};

export default TaskList;
