// TaskDetails.js
import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CircularProgress from '@mui/material/CircularProgress';
import PhoneIcon from '@mui/icons-material/Phone';
import ErrorIcon from '@mui/icons-material/Error';
import TaskIcon from '@mui/icons-material/FormatListNumberedRounded';
import ScheduleIcon from '@mui/icons-material/Schedule';
import MessageIcon from '@mui/icons-material/Message';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Grid from '@mui/material/Grid';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';

const TaskDetails = () => {
  const { taskId } = useParams();
  const [task, setTask] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTaskDetails = async () => {
      try {
        const response = await fetch(`http://localhost:3001/TaskDetails/${taskId}`);
        const data = await response.json();

        if (data.status === 'success') {
          setTask(data.task_details);
        } else {
          setError(data.message);
        }
      } catch (error) {
        setError('Error fetching task details. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchTaskDetails();
  }, [taskId]);

  return (
    <div>

      <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', marginTop: '50px' }}>
      <Link to="/tasks" style={{ textDecoration: 'none', color: 'inherit' }}>
          <IconButton color="primary" aria-label="Go back to Task List">
            <ArrowBackIcon />
          </IconButton>
        </Link>
        <Typography variant="h5" sx={{ marginLeft: '10px' }}>
          Message Details
        </Typography>
      </Grid>
      <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>

        {loading && <CircularProgress style={{ margin: 'auto' }} />}

        {error ? (
          <Typography variant="h6" color="error" style={{ marginTop: '10px' }}>
            {error}
          </Typography>
        ) : task ? (
          <>
            <Typography variant="body1">
              <TaskIcon style={{ marginRight: '8px', verticalAlign: 'middle' }} />
              <strong>Message ID :</strong> {taskId}
            </Typography>

            <Typography variant="body1" style={{ marginTop: '10px' }}>
              <PhoneIcon style={{ marginRight: '8px', verticalAlign: 'middle' }} />
              <strong>Phone Number :</strong> {task.phone_number}
            </Typography>
            <Typography variant="body1" style={{ marginTop: '10px' }}>
              <MessageIcon style={{ marginRight: '8px', verticalAlign: 'middle' }} />
              <strong>Message :</strong> {task.message}
            </Typography>
            <Typography variant="body1" style={{ marginTop: '10px' }}>
  {task.sent === 1 ? (
    <>
      <CheckCircleIcon
        style={{ color: 'green', marginRight: '8px', verticalAlign: 'middle' }}
        aria-label="Task Sent"
      />
      <strong>Status :</strong> Sent
    </>
  ) : task.sent === 0 ? (
    <>
      <HourglassEmptyIcon
        style={{ color: 'orange', marginRight: '8px', verticalAlign: 'middle' }}
        aria-label="Task Pending"
      />
      <strong>Status :</strong> Pending
    </>
  ) : task.sent === 3 ? (
    <>
      <ErrorIcon
        style={{ color: 'red', marginRight: '8px', verticalAlign: 'middle' }}
        aria-label="Task Failed"
      />
      <strong>Status :</strong> Failed
    </>
  ) : null}
</Typography>
            {/* Display sent time with appropriate formatting */}
            {task.timesent && (
              <Typography variant="body1" style={{ marginTop: '10px' }}>
                <ScheduleIcon style={{ marginRight: '8px', verticalAlign: 'middle' }} />
                <strong>Last Updated :</strong> {new Date(task.timesent).toLocaleString()}
              </Typography>
            )}
            {/* Add more details as needed */}
          </>
        ) : (
          !loading && <Typography variant="body1">No task details found.</Typography>
        )}
      </Paper>
    </div>
  );
};

export default TaskDetails;
