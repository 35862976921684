// TaskForm.js
import React, { useState, useRef } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import SendIcon from '@mui/icons-material/Send';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PhoneIcon from '@mui/icons-material/Phone';
import MessageIcon from '@mui/icons-material/Message';
import CircularProgress from '@mui/material/CircularProgress';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { Link } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import swal from 'sweetalert';

const TaskForm = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const isBulk = true; // State to track if it's bulk sending
  const [bulkNumbers, setBulkNumbers] = useState(''); // State to store bulk numbers
  const messageInputRef = useRef(null);

  const characterLimit = 160;

  const handleSubmit = async () => {
    setIsLoading(true);
  
    try {
      // If it's bulk sending, send the same message to multiple numbers
      if (isBulk) {
        const numbersArray = bulkNumbers.split('\n').map((number) => number.trim());
  
        const requests = numbersArray.map(async (number) => {
          try {
            const response = await fetch('http://localhost:3001/QueueTask', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ phone_number: number, message: message }),
            });
  
            const data = await response.json();
  
            if (!response.ok) {
              console.error(`Failed to send to ${number}: ${data.message}`);
            }
  
            return data;
          } catch (error) {
            console.error(`Error sending to ${number}:`, error);
            throw new Error(`Error sending to ${number}: ${error.message}`);
          }
        });
  
        const results = await Promise.all(requests);
  
        // Check if all requests were successful
        if (results.every((result) => result && result.status == 'success')) {
          swal('Success', 'Bulk messages sent successfully.', 'success');
          setBulkNumbers('');
          setMessage('');
        } else {
          throw new Error('Failed to send some or all bulk messages. Please check the console for details.');
        }
      } else {
        // Single request for normal sending
        const response = await fetch('http://localhost:3001/QueueTask', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ phone_number: phoneNumber, message: message }),
        });
  
        const data = await response.json();
  
        if (response.ok) {
          swal('Success', data.message, 'success');
          setPhoneNumber('');
          setMessage('');
        } else {
          throw new Error(data.message || 'Failed to send task. Please try again.');
        }
      }
    } catch (error) {
      console.error('Error submitting task:', error);
      swal('Error', error.message || 'Failed to send task. Please try again.', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const isFormValid = () => {
    // Basic phone number validation (numeric characters only)
    const isValidPhoneNumber = /^\d+$/.test(phoneNumber);
  
    return (
      (!isBulk && isValidPhoneNumber && phoneNumber.trim() !== '' && message.trim() !== '' && message.length <= characterLimit) ||
      (isBulk && bulkNumbers.trim() !== '' && message.trim() !== '' && message.length <= characterLimit)
    );
  };
  

  const handlePhoneNumberChange = (e) => {
    const inputValue = e.target.value.replace(/[^\d+]/g, '');
    setPhoneNumber(inputValue);
  };

  return (
    <Grid container spacing={2} sx={{ mt: 3 }}>
      <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
        <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
          <IconButton color="primary" aria-label="Go back to Home">
            <ArrowBackIcon />
          </IconButton>
        </Link>
        <Typography variant="h5" sx={{ marginLeft: '10px' }}>
          Send Message
        </Typography>
      </Grid>
    
      
        <Grid item xs={12}>
          <TextField
            fullWidth
            label={
              <>
                <PhoneIcon style={{ marginRight: '8px', verticalAlign: 'middle' }} />
                Bulk Mobile Numbers (Enter one mobile number per line)
              </>
            }
            multiline
            placeholder="919484714204"
            inputProps={{ inputMode: 'numeric' }}
            rows={4}
            value={bulkNumbers}
            onChange={(e) => setBulkNumbers(e.target.value)}
          />
        </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label={
            <>
              <MessageIcon style={{ marginRight: '8px', verticalAlign: 'middle' }} />
              Message {message.length}/{characterLimit} characters

            </>
          }
          multiline
          rows={4}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          inputRef={messageInputRef}
          placeholder="Type your message here"
        />
        {message.length > characterLimit && (
          <Typography variant="caption" color="error" sx={{ mt: 1 }}>
            Character limit exceeded!
          </Typography>
        )}
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          fullWidth
          disabled={!isFormValid() || isLoading}
          onClick={handleSubmit}
          endIcon={isLoading ? <CircularProgress size={20} /> : <SendIcon />}
          aria-busy={isLoading}
          aria-disabled={!isFormValid() || isLoading}
        >
          {isLoading ? 'Sending...' : 'Send Message'}
        </Button>
      </Grid>
    </Grid>
  );
};

export default TaskForm;
